<script setup lang="ts">
import { FaqProps, MenuItem } from "./Faq.props"

const isLoading = ref(true)

/**
 * GTM Customer Help Events
 */
const { event, sendEvent } = useGAEvent(`track_customer_help`, "custom_event")

const lastClicked = ref()
/**
 * @description Anchor the page to the fAQ id and send the GTM event
 * @param {MenuItem} entry
 */
const clickOnAccorion = (entry: MenuItem) => {
  const { title, id } = entry

  id ? navigateTo(`#question-${id}`) : null

  if (lastClicked.value != id) {
    event.value = {
      event: "customer_help",
      action: "faq",
      category: "faq",
      label: title
    }
    sendEvent({ clearEcommerce: false })
    lastClicked.value = id
  } else {
    lastClicked.value = null
  }
}

defineProps<FaqProps>()

onMounted(() => {
  isLoading.value = false
})
</script>

<template>
  <div class="faq-wrapper flex flex-col gap-14">
    <div
      :id="`${category?.toLowerCase()}`"
      v-if="faqs"
      v-for="({ category, questionAndAnswers }, index) in faqs"
      :key="`faqSection${index}`"
      class="flex flex-col gap-6 text-left"
    >
      <p
        v-if="category"
        :id="category.toLowerCase()"
        class="elephant-bold text-green-main"
      >
        {{ category }}
      </p>

      <div
        v-if="isLoading"
        v-for="(question, index) in 8"
        :key="`question-${index}`"
        class="
          hidden-no-script
          flex
          items-center
          justify-between
          border-b border-black-10
          pb-6
        "
      >
        <SkeletonAnimatedPlaceholder
          height="35px"
          :width="Math.floor(Math.random() * 50) + 400 + 'px'"
          borderRadius="8px"
        />

        <UtilsIcon name="ChevronDown.svg" class="h-6 w-6" />
      </div>
      <AppAccordion
        v-if="questionAndAnswers"
        :id="id ? `question-${id}` : ''"
        v-for="({ title, text, id }, index) in questionAndAnswers"
        :key="`menuItem${index}`"
        class="
          custom-accordion-faqs
          show-no-script
          flex flex-col
          gap-2
          border-b border-black-10
          pb-6
          text-left text-black-80
        "
        :class="{ hidden: isLoading }"
        :text="title"
        :is-open="null"
        with-icons
        @click="() => clickOnAccorion({ title, text, id })"
      >
        <template #icon-to-open>
          <UtilsIcon name="ChevronDown.svg" class="h-6 w-6" />
        </template>

        <template #icon-to-close>
          <UtilsIcon name="ChevronUp.svg" class="h-6 w-6" />
        </template>

        <template #body>
          <UtilsMarkdown
            v-if="text"
            :content="text"
            class="custom-accordion-faqs"
            tag="div"
          />
        </template>
      </AppAccordion>
    </div>
  </div>
</template>
